* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

$mediawidth: 1068px;
$width: auto;
$primaryColor: #af9a7d;
$mainWhite: #fff;
$offWhite: #f7f7f7;
$mainBlack: #222;
$mainGrey: #ececec;
$darkGrey: #47175e;
$linksColor: rgb(40, 127, 156);
$mainTransition: all 0.3s linear;
$mainSpacing: 3px;
$lightShadow: 2px 5px 3px 0px rgba(0, 0, 0, 0.5);
$darkShadow: 4px 10px 5px 0px rgba(0, 0, 0, 0.5);

body {
    padding-top: 70px;
    max-width: $width;
    color: $mainBlack;
    background: $mainWhite;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    line-height: 1.2;
}

.btn-primary {
    display: inline-block;
    text-decoration: none;
    letter-spacing: $mainSpacing;
    color: $mainBlack;
    background: $mainWhite;
    padding: 0.4rem 0.9rem;
    border: 3px solid $mainWhite;
    transition: $mainTransition;
    text-transform: uppercase;
    cursor: pointer;
}

.btn-primary:hover {
    background: transparent;
    color: $mainWhite;
}

.img {
    margin: 2rem 2rem 2rem 1rem;
    padding: auto;
    width: 15rem;
    border: 5px solid darkGrey;
    border-radius: 15px 15px;
    box-shadow: 3px 3px 3px $mainBlack;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
        box-shadow: 5px 5px 5px $mainBlack;
    }
}




.big-picture{
    margin: 2rem 2rem 2rem 1rem;
    padding: auto;
    width: 40rem;
    border: 5px solid darkGrey;
    border-radius: 15px 15px;
    box-shadow: 3px 3px 3px $mainBlack;
    cursor: pointer;
    &:hover {
        opacity: 0.7;
        box-shadow: 5px 5px 5px $mainBlack;
    }
}
.link {
    color: $linksColor;
    text-decoration: none;
}
@media screen and(min-width:$mediawidth) {
    .img {
        margin: 5rem 5rem 5rem 2rem;
        width: 20rem;
    }

}
/*Navbar*/

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.75rem 2rem;
    background: $offWhite;
    z-index: 1;
    font-size: 10px;
}

.nav-header {
    display: flex;
    justify-content: space-between;
}

.nav-btn {
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
}

.nav-icon {
    font-size: 1.5rem;
    color: $primaryColor;
}

.nav-links {
    height: 0;
    overflow: hidden;
    align-items: center;
    transition: $mainTransition;
    list-style-type: none;
}

.nav-links a {
    display: block;
    text-decoration: none;
    padding: 0.5em 0;
    color: $mainBlack;
    transition: $mainTransition;
    text-align: left;
    font-size: 1.2rem;
    font-weight: bolder;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    letter-spacing: $mainSpacing;
}

.nav-links a:hover {
    color: $primaryColor;
}

.show-nav {
    height: auto;
}

@media screen and (min-width: $mediawidth) {
    .navbar {
        position: fixed;
        width: 100%;
    }
    .nav-btn {
        display: none;
    }
    .nav-center {
        margin: auto;
        display: flex;
    }
    .nav-links {
        height: auto;
        display: flex;
        margin-left: 4rem;
    }
    .nav-links a {
        margin: 0 1rem;
        padding: 0.5rem 0;
    }
}

/*End Navbar*/

/*Banner*/

.banner-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 25rem;
    background-image: url("./images/Cosmos-hero-1.jpg");
    opacity: 0.8;
    color: $mainWhite;
    .banner-center {
        padding: 5rem 2rem;
        .banner-text {
            padding: 2rem;
            text-align: center;
            border: 3px solid $mainWhite;
            border-radius: 5px 5px;
            background-color: $lightShadow;
            letter-spacing: $mainSpacing;

            .btn-primary {
                margin-top: 1rem;
            }
        }
    }
    img {
        width: 100%;
        height: 100%;
        border: 3px solid $mainWhite;
        border-radius: 5px 5px;
    }
    .banner-size {
        width: 20rem;
        height: 14rem;
        box-shadow: 0px 0px 20px $mainWhite;
    }

    @media screen and (max-width: $mediawidth) {
        .banner-center {
            display: none;
        }
    }
}

/*End Banner*/

/*Home, books and about*/

.container {
    width: auto;
   // height: auto;
    overflow: hidden;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    font-size: 1.1rem;


    h1 {
        margin: 1rem 4rem;
        font-size: 1.5rem;
        text-align: center;

    }
    div,
    ul {
        margin-left: auto;
        padding: 1rem;
        li {
            list-style: none;
        }
    }
    .img-center{
        display: block;
        margin-left: auto;
        margin-right: auto;

    }
    img {

        padding: 0;
        margin: 1rem 4rem;
    }

    p {
        margin: 1rem;
        list-style: none;
        text-indent: 2em;
        text-align: justify;
    }
    div.border{
        border: 15px double $mainBlack;
        border-radius: 2rem;
        margin:2rem;
    }
    .center {
        text-align: center;
    }
    .italic {
        font-style: italic;
    }
    pre {
        padding: 1rem;
        font-size: 1rem;
        overflow-wrap: break-word;
        text-align: left;
        div{
            text-align: right;
            font-weight: 900;
        }
    }
}

.btn {
    font-weight: 900;
    font-size: 0.9rem;
    padding: 1rem;
    color: $linksColor;
    outline: none;
    &:hover {
        color: $darkGrey;
    }
}

.show {
    height: auto;
}

@media screen and(min-width:$mediawidth) {
    .container {
        font-size: 1.5rem;
        h1 {
            font-size: 2rem;
        }
        ul {
            margin-left: 10rem;
        }
        pre {
            padding: 1rem 30%;
            font-size: 1.5rem;
        }
    }
    .btn {
        font-size: 1.1rem;
    }
}

/*End home,books and about*/

/*Gallery*/
.image-container {
    flex-wrap: wrap;
    margin: 1rem 4rem;

    h1 {
        margin: 1rem 4rem;
        font-size: 1.5rem;
    }
    span {
        display: inline-block;
        margin: 2rem;
        a {
            width: fit-content;

            img {
                margin: 0;
            }
        }
    }
}

@media screen and(min-width:$mediawidth) {
    .image-container {
        font-size: 1.5rem;
        h1 {
            font-size: 2rem;
        }
    }
}

/*End Gallery*/

/*Contacts*/

.contacts-container {
    font-size: 1.1rem;
    margin: 3rem;
    h1,
    h3 {
        border-bottom: 5px outset black;
        margin: 2rem auto;
    }
    .contact-form-container {
        display: flex;
        flex-direction: column;
        margin: 2rem 1rem;

        div {
            width: 20rem;
            display: flex;
            justify-content: space-between;
            margin: 1rem 0;
            input {
                border: outset;
                outline: none;
                border-radius: 5px;
                font-weight: 900;
            }
            label {
                color: $linksColor;
                text-transform: uppercase;
            }
            .field {
                margin-left: 2rem;
                width: 70%;
            }
            .message {
                width: 70%;
            }
        }
        a {
            width: fit-content;
            .btn:disabled {
                color: gray;
                cursor: not-allowed;
            }
        }
    }
}
@media screen and(min-width:$mediawidth) {
    .contacts-container {
        font-size: 1.5rem;
        .contact-form-container {
            div {
                width: 35rem;
            }
        }
    }
}

/*End Contacts*/

/*Not Found*/
.not-found {
    margin: 0 5rem 5rem 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    h1 {
        margin: 2rem 0;
        color: $primaryColor;
        font-size: 1.5rem;
    }
    img {
        width: 25rem;
    }
}

/*End Not Found*/

/*Poets*/

.poets-container {
    margin: 5rem 0;
    text-align: center;
    display: flex;
    flex-direction: column;
    text-transform: uppercase;
    h1 {
        font-size: 1.5rem;
        margin: 1rem;
    }
    .image-container {
        width: 15rem;
        display: inline-block;
        margin: 2rem auto;
        img {
            width: 100%;
            border: 5px solid darkGrey;
            border-radius: 15px 15px;
            box-shadow: 3px 3px 3px $mainBlack;
            cursor: zoom-in;
            &:hover {
                opacity: 0.7;
                box-shadow: 5px 5px 5px $mainBlack;
            }
        }
    }

    .facebook-link {
        text-decoration: none;
        color: $linksColor;
        font-size: 1rem;
        &:hover {
            color: $darkGrey;
        }
    }
}

@media screen and(min-width:$mediawidth) {
    .poets-container {
        font-size: 1.5rem;
        h1 {
            font-size: 2rem;
        }
        .image-container {
            width: 30rem;
        }
        .facebook-link {
            font-size: 2rem;
        }
    }
}
/*End Poets*/

/*cookie policy*/
.cookie-policy {
    padding: 1rem;
    font-size: 1.2rem;
    h1,
    h2 {
        padding: 1rem 0;
    }
}

/*end cookie policy*/

/*Footer*/
.footer-container {
    margin-top: 5rem;
    padding: 1rem 0;
    background: $offWhite;
    h4 {
        text-align: center;
        font-size: 1.2rem;
    }
    .social-container {
        text-align: center;
        a.social {
            margin: 0 1rem;
            transition: transform 250ms;
            display: inline-block;
            &.social:hover {
                transform: translateY(-3px);
            }
        }

        a.youtube {
            color: #eb3223;
        }

        a.facebook {
            color: #4968ad;
        }

        a.twitter {
            color: #49a1eb;
        }

        a.instagram {
            color: black;
        }
    }
}

/*End Footer*/
